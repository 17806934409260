export const yesNoAnswer = {
    yes: "yes",
    no: "no",
} as const;

export const countAnswer = {
    lessThanOne: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    tenOrMore: "10",
} as const;

export const timeAnswer = {
    lessThanThirtyMinutes: "0",
    thirtyMinutes: "0.5",
    fortyFiveMinutes: "0.75",
    oneHour: "1",
    oneHourThirtyMinutes: "1.5",
    twoHours: "2",
    twoHoursThirtyMinutes: "2.5",
    threeHours: "3",
    threeHoursThirtyMinutes: "3.5",
    fourHours: "4",
    fourHoursThirtyMinutes: "4.5",
    fiveOrMoreHours: "5",
} as const;
