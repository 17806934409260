import OverviewPage from "@/features/lifestyle-medicine-assessment/pages/overview/page.tsx";
import ProfilePage from "@/features/lifestyle-medicine-assessment/pages/profile/page.tsx";
import QuestionsPage from "@/features/lifestyle-medicine-assessment/pages/questions/page.tsx";
import ResultsPage from "@/features/lifestyle-medicine-assessment/pages/results/page.tsx";
import HistoryPage from "@/features/lifestyle-medicine-assessment/pages/history/page.tsx";

export const pages = {
    OverviewPage,
    ProfilePage,
    QuestionsPage,
    ResultsPage,
    HistoryPage,
};

export type {
    CompletedAssessment,
    Scores,
    ScoreCategory,
} from "@/features/lifestyle-medicine-assessment/types.ts";
