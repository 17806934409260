import { Head, Link, useForm } from "@inertiajs/react";
import { Button } from "@/components/ui/button.tsx";
import {
    Card,
    CardContent,
    CardHeader,
    CardFooter,
} from "@/components/ui/card";
import { LifestyleMedicineAssessmentTitle } from "@/features/lifestyle-medicine-assessment/components/lifestyle-medicine-assessment-title.tsx";

export default function OverviewPage() {
    const { processing } = useForm();

    return (
        <div className="flex flex-col space-y-10">
            <Head title="Overview | Lifestyle Medicine Assessment" />
            <Card>
                <CardHeader>
                    <LifestyleMedicineAssessmentTitle />
                </CardHeader>
                <CardContent className="flex justify-center">
                    <div className="md:w-[80%]">
                        <p className="mb-2">
                            The Lifestyle Medicine Assessment consists of 21
                            questions covering 5 categories of modifiable
                            behaviors associated with positive health outcomes.
                        </p>
                        <p>
                            The assessment takes approximately 5 minutes to
                            complete.
                        </p>
                    </div>
                </CardContent>
                <CardFooter className="flex-col">
                    <div className="justify-center gap-2 mb-6">
                        <Button type="submit" disabled={processing} asChild>
                            <Link
                                href={route(
                                    "lifestyle-medicine-assessment.assessments.create",
                                )}
                            >
                                Start
                            </Link>
                        </Button>
                    </div>
                    <p className="text-xs text-muted-foreground">
                        <span className="mr-1">
                            By taking this assessment you are agreeing to Apasas
                            Health's
                        </span>
                        <a
                            href="/terms-of-service"
                            className="text-primary underline font-bold mr-1"
                            target="_blank"
                        >
                            Terms of Service
                        </a>
                        <span className="mr-1">and</span>
                        <a
                            href="/privacy-policy"
                            className="text-primary underline font-bold"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                    </p>
                </CardFooter>
            </Card>
        </div>
    );
}
