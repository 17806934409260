import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx";
import {
    FormControl,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form.tsx";
import { YesNoAnswer } from "@/features/lifestyle-medicine-assessment/pages/questions/types.ts";
import { yesNoAnswer } from "@/features/lifestyle-medicine-assessment/pages/questions/values.ts";

type YesNoAnswerFormFieldProps = {
    label: string;
    name: string;
    value?: YesNoAnswer;
    onChange: (value: YesNoAnswer) => void;
    error?: string;
};
export function YesNoAnswerFormField({
    name,
    label,
    value,
    onChange,
    error,
}: YesNoAnswerFormFieldProps) {
    return (
        <FormItem name={name} error={error}>
            <FormLabel>{label}</FormLabel>
            <FormControl>
                <RadioGroup
                    defaultValue={value}
                    onValueChange={onChange}
                    className="flex items-center space-x-3 space-y-0"
                >
                    <RadioGroupItem label="Yes" value={yesNoAnswer.yes} />
                    <RadioGroupItem label="No" value={yesNoAnswer.no} />
                </RadioGroup>
            </FormControl>
            <FormMessage />
        </FormItem>
    );
}
