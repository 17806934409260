import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select.tsx";
import {
    FormItem,
    FormLabel,
    FormMessage,
    FormControl,
} from "@/components/ui/form.tsx";
import { countAnswer } from "@/features/lifestyle-medicine-assessment/pages/questions/values.ts";
import { CountAnswer } from "@/features/lifestyle-medicine-assessment/pages/questions/types.ts";

type CountAnswerFormFieldProps = {
    label: string;
    name: string;
    value?: CountAnswer;
    onChange: (value: CountAnswer) => void;
    error?: string;
};

export function CountAnswerFormField({
    label,
    name,
    value,
    onChange,
    error,
}: CountAnswerFormFieldProps) {
    return (
        <FormItem name={name} error={error}>
            <FormLabel>{label}</FormLabel>
            <Select onValueChange={onChange} defaultValue={value}>
                <FormControl>
                    <SelectTrigger>
                        <SelectValue placeholder="Select amount" />
                    </SelectTrigger>
                </FormControl>
                <FormMessage />
                <SelectContent>
                    <SelectItem value={countAnswer.lessThanOne}>
                        Less than 1
                    </SelectItem>
                    <SelectItem value={countAnswer.one}>1</SelectItem>
                    <SelectItem value={countAnswer.two}>2</SelectItem>
                    <SelectItem value={countAnswer.three}>3</SelectItem>
                    <SelectItem value={countAnswer.four}>4</SelectItem>
                    <SelectItem value={countAnswer.five}>5</SelectItem>
                    <SelectItem value={countAnswer.six}>6</SelectItem>
                    <SelectItem value={countAnswer.seven}>7</SelectItem>
                    <SelectItem value={countAnswer.eight}>8</SelectItem>
                    <SelectItem value={countAnswer.nine}>9</SelectItem>
                    <SelectItem value={countAnswer.tenOrMore}>
                        10 or more
                    </SelectItem>
                </SelectContent>
            </Select>
        </FormItem>
    );
}
