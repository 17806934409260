import { FormEventHandler, useEffect } from "react";
import { Head, Link, useForm } from "@inertiajs/react";
import { Button } from "@/components/ui/button.tsx";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardFooter,
} from "@/components/ui/card.tsx";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx";
import {
    Form,
    FormItem,
    FormLabel,
    FormMessage,
    FormControl,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { sexAnswer } from "@/features/profile/values.ts";
import type { SexAnswer } from "@/features/profile/types.ts";
import { ProfileForm } from "@/features/lifestyle-medicine-assessment/pages/profile/types.ts";
import { useToast } from "@/components/ui/use-toast";

export default function ProfilePage() {
    const { toast } = useToast();
    const { data, errors, setData, post, processing } = useForm<ProfileForm>({
        name: "",
        email: "",
        sex: undefined,
    });

    const onSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        post(route("lifestyle-medicine-assessment.profile.store"));
    };

    useEffect(() => {
        if (errors && Object.keys(errors).length > 0 && !processing) {
            toast({
                title: "There were errors with your submission. Please check for missing or incorrect values.",
                variant: "destructive",
            });
        }
    }, [toast, errors, processing]);

    return (
        <>
            <Head title="About You | Lifestyle Medicine Assessment" />
            <Card>
                <CardHeader>
                    <CardTitle>About You</CardTitle>
                </CardHeader>
                <CardContent>
                    <Form id="profile" onSubmit={onSubmit}>
                        <FormItem name="name" error={errors.name}>
                            <FormLabel>Name</FormLabel>
                            <FormControl>
                                <Input
                                    value={data.name}
                                    onChange={(e) =>
                                        setData("name", e.target.value)
                                    }
                                    placeholder="Your name"
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                        <FormItem name="email" error={errors.email}>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input
                                    value={data.email}
                                    onChange={(e) =>
                                        setData("email", e.target.value)
                                    }
                                    placeholder="name@me.com"
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                        <FormItem name="sex" error={errors.sex}>
                            <FormLabel>Assigned Gender at Birth</FormLabel>
                            <FormControl>
                                <RadioGroup
                                    onValueChange={(value: SexAnswer) =>
                                        setData("sex", value)
                                    }
                                    className="flex items-center space-x-3 space-y-0"
                                >
                                    <RadioGroupItem
                                        label="Male"
                                        value={sexAnswer.male}
                                    />
                                    <RadioGroupItem
                                        label="Female"
                                        value={sexAnswer.female}
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </Form>
                </CardContent>
                <CardFooter className="justify-center space-x-1">
                    <Button variant="outline" asChild>
                        <Link
                            href={route(
                                "lifestyle-medicine-assessment.overview.show",
                            )}
                        >
                            Back
                        </Link>
                    </Button>
                    <Button form="profile" type="submit" disabled={processing}>
                        Next
                    </Button>
                </CardFooter>
            </Card>
        </>
    );
}
