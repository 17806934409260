import { useCopyToClipboard } from "usehooks-ts";
import { useToast } from "@/components/ui/use-toast.ts";

export function useCopyLinkToClipboard() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, copy] = useCopyToClipboard();
    const { toast } = useToast();

    async function handleLinkCopy() {
        try {
            await copy(window.location.href);
            toast({
                description:
                    "Link to assessment results has been copied to your clipboard",
            });
        } catch {
            toast({
                description: "Unable to copy assessment link",
                variant: "destructive",
            });
        }
    }

    return {
        handleLinkCopy,
    };
}
