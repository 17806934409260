import { Button } from "@/components/ui/button.tsx";
import { Head, Link, useForm } from "@inertiajs/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { PageProps, PaginatedResponse } from "@/types/app";
import { CompletedAssessment } from "@/features/lifestyle-medicine-assessment/types.ts";
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card.tsx";

import { LifestyleMedicineAssessmentScore } from "@/features/lifestyle-medicine-assessment/components/lifestyle-medicine-assessment-score.tsx";

export default function HistoryPage({
    assessments,
}: PageProps<{
    assessments: PaginatedResponse<CompletedAssessment[]>;
}>) {
    const { processing } = useForm();

    if (assessments.data.length === 0) {
        return null;
    }

    return (
        <div className="flex flex-col space-y-4">
            <Head title="History | Lifestyle Medicine Assessment" />
            <Card>
                <CardHeader>
                    <CardTitle>Assessment History</CardTitle>
                </CardHeader>
                <CardContent className="md:px-0">
                    <ul className="flex flex-col">
                        {assessments.data.map((assessment) => (
                            <li
                                key={assessment.id}
                                className="flex flex-row items-center px-4 md:px-6 py-4 border-t last:border-b transition-colors hover:bg-accent/50"
                            >
                                <div className="flex-1">
                                    <LifestyleMedicineAssessmentScore
                                        score={assessment.scores.total}
                                    />
                                    <p className="text-muted-foreground">
                                        {new Date(
                                            assessment.created_at,
                                        ).toLocaleDateString()}
                                    </p>
                                </div>
                                <div>
                                    <Button variant="ghost" asChild>
                                        <a
                                            target="_blank"
                                            href={route(
                                                "lifestyle-medicine-assessment.assessments.show",
                                                { id: assessment.public_id },
                                            )}
                                        >
                                            View
                                        </a>
                                    </Button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </CardContent>
                <CardFooter className="justify-center">
                    {assessments.prev_page_url && (
                        <Button
                            variant="secondary"
                            disabled={processing}
                            asChild
                        >
                            <Link href={assessments.prev_page_url}>
                                <ChevronLeft size={20} />
                                Back
                            </Link>
                        </Button>
                    )}
                    {assessments.next_page_url && (
                        <Button
                            variant="secondary"
                            disabled={processing}
                            asChild
                        >
                            <Link href={assessments.next_page_url}>
                                Next
                                <ChevronRight size={20} />
                            </Link>
                        </Button>
                    )}
                </CardFooter>
            </Card>
        </div>
    );
}
