import { Head, Link } from "@inertiajs/react";
import { Button } from "@/components/ui/button.tsx";
import {
    Card,
    CardHeader,
    CardTitle,
    CardContent,
    CardFooter,
} from "@/components/ui/card.tsx";
import { Scores } from "@/features/lifestyle-medicine-assessment/types.ts";
import { LifestyleMedicineAssessmentTitle } from "@/features/lifestyle-medicine-assessment/components/lifestyle-medicine-assessment-title.tsx";
import { LifestyleMedicineAssessmentScore } from "@/features/lifestyle-medicine-assessment/components/lifestyle-medicine-assessment-score.tsx";
import { useCopyLinkToClipboard } from "@/features/lifestyle-medicine-assessment/pages/results/use-copy-link-to-clipboard.ts";
import { Chart } from "@/features/lifestyle-medicine-assessment/pages/results/chart.tsx";

export default function ResultsPage({ scores }: { scores: Scores }) {
    const { handleLinkCopy } = useCopyLinkToClipboard();

    return (
        <div className="flex flex-col space-y-4">
            <Head title="Results | Lifestyle Medicine Assessment" />
            <LifestyleMedicineAssessmentTitle />
            <Card>
                <CardHeader>
                    <CardTitle className="text-center font-semibold">
                        Your Lifestyle Score is:
                    </CardTitle>
                    <LifestyleMedicineAssessmentScore
                        score={scores.total}
                        className="text-2xl text-center"
                    />
                </CardHeader>
                <CardContent>
                    <Chart scores={scores} className="m-auto w-full md:w-1/2" />
                </CardContent>
                <CardFooter className="justify-center gap-2">
                    <Button variant="ghost" asChild>
                        <Link
                            href={route(
                                "lifestyle-medicine-assessment.overview.show",
                            )}
                        >
                            Take Again
                        </Link>
                    </Button>
                    <Button onClick={handleLinkCopy}>Copy Link</Button>
                </CardFooter>
            </Card>
        </div>
    );
}
