import { useEffect, FormEventHandler } from "react";
import {
    AssessmentAnswers,
    AssessmentUser,
} from "@/features/lifestyle-medicine-assessment/pages/questions/types.ts";
import { Head, useForm, Link } from "@inertiajs/react";
import { Button } from "@/components/ui/button.tsx";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card.tsx";
import { Form } from "@/components/ui/form.tsx";
import { YesNoAnswerFormField } from "@/features/lifestyle-medicine-assessment/pages/questions/components/form/yes-no-answer-form-field.tsx";
import { CountAnswerFormField } from "@/features/lifestyle-medicine-assessment/pages/questions/components/form/count-answer-form-field.tsx";
import { TimeAnswerFormField } from "@/features/lifestyle-medicine-assessment/pages/questions/components/form/time-answer-form-field.tsx";
import { LifestyleMedicineAssessmentTitle } from "@/features/lifestyle-medicine-assessment/components/lifestyle-medicine-assessment-title.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

type AssessmentPageProps = {
    user: AssessmentUser;
    errors: Record<keyof AssessmentAnswers, string>;
};

export default function QuestionsPage({ user }: AssessmentPageProps) {
    const { toast } = useToast();
    const { data, setData, errors, post, transform, processing } =
        useForm<AssessmentAnswers>();

    const onSubmit: FormEventHandler = function (e) {
        e.preventDefault();

        transform((data) => ({
            ...data,
            ageQuestion: user.age,
            sexQuestion: user.sex,
        }));

        post(route("lifestyle-medicine-assessment.assessments.store"));
    };

    useEffect(() => {
        if (errors && Object.keys(errors).length > 0 && !processing) {
            document.getElementById("root-layout-scroll-container")?.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            toast({
                title: "There were errors with your submission. Please check for missing or incorrect values.",
                variant: "destructive",
            });
        }
    }, [errors, processing, toast]);

    return (
        <Form id="lma-form" onSubmit={onSubmit} className="space-y-6">
            <Head title="Questions | Lifestyle Medicine Assessment" />
            <LifestyleMedicineAssessmentTitle />
            <Card>
                <CardHeader>
                    <CardTitle className="text-lg font-bold">
                        For each item, think about the last seven days and
                        answer ‘Yes’ or ‘No’ if you:
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                    <YesNoAnswerFormField
                        name="purposeQuestion"
                        label="Felt your life had a sense of purpose"
                        value={data.purposeQuestion}
                        onChange={(value) => setData("purposeQuestion", value)}
                        error={errors.purposeQuestion}
                    />
                    <YesNoAnswerFormField
                        name="cookingOilQuestion"
                        label="Used olive oil as your primary oil or used no oil when cooking"
                        value={data.cookingOilQuestion}
                        onChange={(value) =>
                            setData("cookingOilQuestion", value)
                        }
                        error={errors.cookingOilQuestion}
                    />
                    <YesNoAnswerFormField
                        name="spiritualQuestion"
                        label="Engaged in two or more spiritual or religious practices (e.g., meditation, prayer, church services, etc.)"
                        value={data.spiritualQuestion}
                        onChange={(value) =>
                            setData("spiritualQuestion", value)
                        }
                        error={errors.spiritualQuestion}
                    />
                    <YesNoAnswerFormField
                        name="stressQuestion"
                        label="Felt that you were able to manage and deal with stressors effectively most days"
                        value={data.stressQuestion}
                        onChange={(value) => setData("stressQuestion", value)}
                        error={errors.stressQuestion}
                    />
                    <YesNoAnswerFormField
                        name="communityQuestion"
                        label="Interacted with one or more club(s) or organization(s) (e.g., athletic, community, school group, etc.)"
                        value={data.communityQuestion}
                        onChange={(value) =>
                            setData("communityQuestion", value)
                        }
                        error={errors.communityQuestion}
                    />
                    <YesNoAnswerFormField
                        name="smokingQuestion"
                        label="Smoked, vaped, or used tobacco/e-cigarette"
                        value={data.smokingQuestion}
                        onChange={(value) => setData("smokingQuestion", value)}
                        error={errors.smokingQuestion}
                    />
                    <YesNoAnswerFormField
                        name="familyQuestion"
                        label="Visited or spoke to a close friend or family member on three or more separate occasions"
                        value={data.familyQuestion}
                        onChange={(value) => setData("familyQuestion", value)}
                        error={errors.familyQuestion}
                    />
                    <YesNoAnswerFormField
                        name="refreshedQuestion"
                        label="Woke up feeling refreshed and rested on most days"
                        value={data.refreshedQuestion}
                        onChange={(value) =>
                            setData("refreshedQuestion", value)
                        }
                        error={errors.refreshedQuestion}
                    />
                    <YesNoAnswerFormField
                        name="natureQuestion"
                        label="Spent at least two hours in nature (approximately 20 minutes daily)"
                        value={data.natureQuestion}
                        onChange={(value) => setData("natureQuestion", value)}
                        error={errors.natureQuestion}
                    />
                    <YesNoAnswerFormField
                        name="careQuestion"
                        label="Felt you had enough time to take care of yourself most days"
                        value={data.careQuestion}
                        onChange={(value) => setData("careQuestion", value)}
                        error={errors.careQuestion}
                    />
                </CardContent>
            </Card>
            <Card>
                <CardHeader>
                    <CardTitle className="text-lg font-bold">
                        For each item, think about the last seven days and
                        provide your best estimate for each:
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                    <CountAnswerFormField
                        label="Total number of sit-down or take-out restaurant meals"
                        name="eatingOutQuestion"
                        value={data.eatingOutQuestion}
                        onChange={(value) =>
                            setData("eatingOutQuestion", value)
                        }
                        error={errors.eatingOutQuestion}
                    />
                    <CountAnswerFormField
                        label="Total number of resistance training workouts performed (e.g., pushups, squats, pullups, etc.)"
                        name="resistanceTrainingQuestion"
                        value={data.resistanceTrainingQuestion}
                        onChange={(value) =>
                            setData("resistanceTrainingQuestion", value)
                        }
                        error={errors.resistanceTrainingQuestion}
                    />
                    <CountAnswerFormField
                        label="Total number of sweetened drinks consumed (e.g., juice, sweetened coffee or tea, soda, sports drinks, etc.)"
                        name="sweetenedDrinksQuestion"
                        value={data.sweetenedDrinksQuestion}
                        onChange={(value) =>
                            setData("sweetenedDrinksQuestion", value)
                        }
                        error={errors.sweetenedDrinksQuestion}
                    />
                    <CountAnswerFormField
                        label="Highest number of alcoholic drinks consumed on any single day"
                        name="highestAlcoholicDrinksQuestion"
                        value={data.highestAlcoholicDrinksQuestion}
                        onChange={(value) =>
                            setData("highestAlcoholicDrinksQuestion", value)
                        }
                        error={errors.highestAlcoholicDrinksQuestion}
                    />
                    <CountAnswerFormField
                        label="Average number of packaged snacks per day (e.g., chips, crackers, cookies, candy, protein bars, etc.)"
                        name="packagedSnacksQuestion"
                        value={data.packagedSnacksQuestion}
                        onChange={(value) =>
                            setData("packagedSnacksQuestion", value)
                        }
                        error={errors.packagedSnacksQuestion}
                    />
                    <CountAnswerFormField
                        label="Average number of hours slept per night"
                        name="sleepQuestion"
                        value={data.sleepQuestion}
                        onChange={(value) => setData("sleepQuestion", value)}
                        error={errors.sleepQuestion}
                    />
                    <CountAnswerFormField
                        label="Average number of daily servings of fruit"
                        name="fruitsQuestion"
                        value={data.fruitsQuestion}
                        onChange={(value) => setData("fruitsQuestion", value)}
                        error={errors.fruitsQuestion}
                    />
                    <CountAnswerFormField
                        label="Average number of hours spent sitting each day"
                        name="sittingQuestion"
                        value={data.sittingQuestion}
                        onChange={(value) => setData("sittingQuestion", value)}
                        error={errors.sittingQuestion}
                    />
                    <CountAnswerFormField
                        label="Average number of alcoholic drinks consumed on days alcohol was consumed (select less than one if you did not drink any alcohol)"
                        name="averageAlcoholicDrinksQuestion"
                        value={data.averageAlcoholicDrinksQuestion}
                        onChange={(value) =>
                            setData("averageAlcoholicDrinksQuestion", value)
                        }
                        error={errors.averageAlcoholicDrinksQuestion}
                    />
                    <CountAnswerFormField
                        label="Average number of daily servings of vegetables"
                        name="vegetablesQuestion"
                        value={data.vegetablesQuestion}
                        onChange={(value) =>
                            setData("vegetablesQuestion", value)
                        }
                        error={errors.vegetablesQuestion}
                    />
                    <TimeAnswerFormField
                        label="Total amount of cardiorespiratory exercise during the week (e.g., brisk walk, jog, etc.)"
                        name="cardioQuestion"
                        value={data.cardioQuestion}
                        onChange={(value) => setData("cardioQuestion", value)}
                        error={errors.cardioQuestion}
                    />
                </CardContent>
            </Card>
            <div className="flex justify-center space-x-2">
                <Button variant="ghost" asChild>
                    <Link
                        href={route(
                            "lifestyle-medicine-assessment.overview.show",
                        )}
                    >
                        Restart
                    </Link>
                </Button>
                <Button form="lma-form" type="submit" disabled={processing}>
                    Submit
                </Button>
            </div>
        </Form>
    );
}
