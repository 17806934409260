import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select.tsx";
import {
    FormItem,
    FormLabel,
    FormMessage,
    FormControl,
} from "@/components/ui/form.tsx";
import { timeAnswer } from "@/features/lifestyle-medicine-assessment/pages/questions/values.ts";
import { TimeAnswer } from "@/features/lifestyle-medicine-assessment/pages/questions/types.ts";

type TimeAnswerFormFieldProps = {
    label: string;
    name: string;
    value?: TimeAnswer;
    onChange: (value: TimeAnswer) => void;
    error?: string;
};

export function TimeAnswerFormField({
    label,
    name,
    value,
    onChange,
    error,
}: TimeAnswerFormFieldProps) {
    return (
        <FormItem name={name} error={error}>
            <FormLabel>{label}</FormLabel>
            <Select onValueChange={onChange} defaultValue={value}>
                <FormControl>
                    <SelectTrigger>
                        <SelectValue placeholder="Select amount" />
                    </SelectTrigger>
                </FormControl>
                <FormMessage />
                <SelectContent>
                    <SelectItem value={timeAnswer.lessThanThirtyMinutes}>
                        Less 30 minutes
                    </SelectItem>
                    <SelectItem value={timeAnswer.thirtyMinutes}>
                        30 minutes
                    </SelectItem>
                    <SelectItem value={timeAnswer.fortyFiveMinutes}>
                        45 minutes
                    </SelectItem>
                    <SelectItem value={timeAnswer.oneHour}>1 hour</SelectItem>
                    <SelectItem value={timeAnswer.oneHourThirtyMinutes}>
                        1 hour and 30 minutes
                    </SelectItem>
                    <SelectItem value={timeAnswer.twoHours}>2 hours</SelectItem>
                    <SelectItem value={timeAnswer.twoHoursThirtyMinutes}>
                        2 hours and 30 minutes
                    </SelectItem>
                    <SelectItem value={timeAnswer.threeHours}>
                        3 hours
                    </SelectItem>
                    <SelectItem value={timeAnswer.threeHoursThirtyMinutes}>
                        3 hours and 30 minutes
                    </SelectItem>
                    <SelectItem value={timeAnswer.fourHours}>
                        4 hours
                    </SelectItem>
                    <SelectItem value={timeAnswer.fourHoursThirtyMinutes}>
                        4 hours and 30 minutes
                    </SelectItem>
                    <SelectItem value={timeAnswer.fiveOrMoreHours}>
                        5 or more hours
                    </SelectItem>
                </SelectContent>
            </Select>
        </FormItem>
    );
}
